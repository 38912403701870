import PropTypes from "prop-types"
import React from "react"

const TitleH2 = ({ titleName, align }) => (
  <div className={"container container-h2 " + align}>
    <h2>{titleName}</h2>
  </div>
)

TitleH2.propTypes = {
  titleName: PropTypes.string,
  align: PropTypes.string,
}

TitleH2.defaultProps = {
  titleName: ``,
  align: `center`,
}

export default TitleH2