import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TitleH2 from "../components/globals/title-h2"

import BackgroundHeader from "../components/staffing/background-header"
import QueHacemos from "../components/staffing/que-hacemos"

import ImageComoStaffing from "../components/staffing/image-como-staffing"
import ImageStaffingEventos from "../components/staffing/image-staffing-eventos"
import ImageStaffingBeneficios from "../components/staffing/image-staffing-beneficios"

import conocimientos from "../images/staffing-conocimientos.png"
import skills from "../images/staffing-skills.png"
import trabajo from "../images/staffing-trabajo.png"
import beneficiosLogo from "../images/beneficios-logo.png"

import SimpleSlider from "../components/globals/simple-slider"

import "../scss/staffing-styles.scss"

const StaffingTi = () => (
  <Layout footer={true} menuactive="staffing">
    <SEO title="Staffing TI" />
    <BackgroundHeader />
    <div className="container padding-0">
      <div className="container-warped">
        <TitleH2 titleName="¿Qué hacemos?" align="left"></TitleH2>
        <p className="txt-description">
          ¿Las demandas de tu proyecto exigen la contratación inmediata de un grupo de especialistas en desarrollo de software? ¡Nosotros te apoyamos!<br /><br />
          Proveemos a tu organización de un grupo de expertos para trabajar en tus proyectos de desarrollo de software, de forma local o remota, por el tiempo que los necesites.
        </p>
      </div>
    </div>
    <QueHacemos />
    <div className="row bkg-gray">
      <div className="col txt-container">
        <h2 className="h3-big padding-container right">¿Cómo lo <br />hacemos?</h2>
        <div className="padding-container right">
          <SimpleSlider />
        </div>
      </div>
      <div className="col image__responsive">
        <ImageComoStaffing />
      </div>
    </div>
    <div className="container padding-0">
      <div className="container-warped center">
        <TitleH2 titleName="Valor agregado" align="center bkg-white" />
        <p className="txt-description">
          Capacitamos continuamente a nuestro equipo de<br />
          especialistas y los apoyamos para que mejoren:
        </p>
      </div>
    </div>
    <div className="container padding-0">
      <div className="container-warped center">
        <div className="row">
          <div className="col-3 txt-container">
            <img alt="conocimientos" className="image-staffing conocimientos" src={conocimientos} />
            <span className="txt-description">
              Sus conocimientos<br /> tecnológicos
            </span>
          </div>
          <div className="col-3 txt-container">
            <img alt="skills" className="image-staffing skills" src={skills} />
            <span className="txt-description">
              Los soft skills <br />que necesitan
            </span>
          </div>
          <div className="col-3 txt-container">
            <img alt="trabajo" className="image-staffing trabajo" src={trabajo} />
            <span className="txt-description">
              La calidad de <br />su trabajo
            </span>
          </div>
        </div>  
      </div>
    </div>
    <div className="row">
      <div className="col-2_3 image__responsive">
        <ImageStaffingEventos />
      </div>
      <div className="col-1_3 txt-container">
        <h2 className="padding-container">Eventos</h2>
        <div className="txt-description-body padding-container">
          <p>Como parte de la capacitación continua, todos nuestros ingenieros participan en eventos como:</p>
        </div>
        <div className="txt-description padding-container">
          <ul className="list-1">
            <li>Hackatones</li>
            <li>Bootcamps</li>
            <li>Conferencias</li>
            <li>Expos</li>
          </ul>
        </div>
      </div>
    </div>
    <div className="row bkg-gray">
      <div className="image-beneficios">
        <div className="container-warped center">
          <TitleH2 titleName="Beneficios" align="center " />
        </div>
        <ImageStaffingBeneficios />
      </div>
    </div>
    <div className="container-warped beneficios-movil">
      <TitleH2 titleName="Beneficios" align="center " />
      <div className="container-warped bkg-gray">
        <img alt="logo" className="beneficios-logo" src={beneficiosLogo}></img>
        <div className="container-warped__item tiempo">
          <h4>Tiempo</h4>
          <span>
            Ayudamos a reducir los tiempos de ejecición de tus
            proyectos y aumentamos significativamente la productividad
            de tu empresa sin incrementar el "headcount".
          </span>
        </div>
        <div className="container-warped__item costo">
          <h4>Costo</h4>
          <span>
            Absorbemos los costos de reclutamiento, selección de personal, capacitaciones
            y formación continua de las personas, somos los responsables de nómina, contratos, liquidaciones y otros servicios.
          </span>
        </div>
        <div className="container-warped__item enfoque">
          <h4>Enfoque</h4>
          <span>
            Enfoca tus esfuerzos en la ejecución del "Core Bussiness" de tu empresa y permítenos apoyarte con un equipo de especialistas
            en Tecnologías de la Información que te ayudarán a conseguir los objetivos estratégicos de tu organización.
          </span>
        </div>
        <div className="container-warped__item calidad">
          <h4>Calidad</h4>
          <span>
            Contamos con personal altamente calificado, especialistas en su área
            de conocimiento. Los capacitamos y formamos permanentemente para
            que estén a la altura de los cambios tecnológicos.
          </span>
        </div>
        <div className="container-warped__item riesgo">
          <h4>Riesgo</h4>
          <span>
            Reducimos el riesgo de los proyectos y de la operación
            de tu empresa al colocar de manera eficiente al personal indicado en los puestos clave de tu organización.
          </span>
        </div>
        <div className="container-warped__item high-flyers">
          <h4>High Flyers</h4>
          <span>
            Nosotros asumimos la calidad de patrón frente a nuestros 
            empleados, aún cuando realicen labores para tu empresa. Cumplimos
            con todas las obligaciones legales y normativas como: pago de contribuciones,
            seguridad social, INFONAVIT, sistema de ahorro para el retiro, etc.
          </span>
        </div>
      </div>
    </div>
    <div className="container padding-0 bkg-gradiant-orange footer-padding">
      <div className="container-warped center">
        <TitleH2 titleName="Modelo flexible" align="center text-white" />
        <table className="table-beneficios bold">
          <tbody>
          <tr>
            <td className="text-left">Jr</td>
            <td className="middle">
              <span>Perfiles</span>
            </td>
            <td className="text-right">Sr</td>
          </tr>
          <tr>
            <td className="text-left">Corto</td>
            <td className="middle">
              <span>Tiempo</span>
            </td>
            <td className="text-right">Largo</td>
          </tr>
          <tr>
            <td className="text-left">Local</td>
            <td className="middle">
              <span>Sitio</span>
            </td>
            <td className="text-right">Remoto</td>
          </tr>
          <tr>
            <td className="text-left">Con</td>
            <td className="middle">
              <span>Laptop</span>
            </td>
            <td className="text-right">Sin</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Layout>
)

export default StaffingTi
