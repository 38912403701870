import React from "react"

const QueHacemos = () => (
  <>
    <div className="container padding-0">
      <div className="container-warped-staffing">
        <div className="row">
          <div className="col">
            <div className="container-warped-staffing__item bkg-orange bkg-ios">
              <div className="container-warped-staffing__item__text">
                <h3>iOS</h3>
                <ul className="list-staffing-1">
                  <li className="bullet2">Especialistas en desarrollo de aplicaciones nativas e híbridas</li>
                  <li className="bullet2">Conocimientos de  SWift 4, Objective-C, OCUnit, Cocoa Touch</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="container-warped-staffing__item bkg-blue bkg-android">
              <div className="container-warped-staffing__item__text">
                <h3>Android</h3>
                <ul className="list-staffing-1">
                  <li className="bullet1">Especialistas en desarrollo de aplicaciones nativas e híbridas</li>
                  <li className="bullet1">Conocimientos de Android, Kotlin, JUnit, Java</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container padding-0">
      <div className="container-warped-staffing">
        <div className="row">
          <div className="col">
            <div className="container-warped-staffing__item bkg-orange bkg-web">
              <div className="container-warped-staffing__item__text">
                <h3>Web</h3>
                <ul className="list-staffing-1">
                  <li className="bullet2">Especialistas en desarrollo de aplicaciones Web empresariales</li>
                  <li className="bullet2">Conocimientos de  HTML5, CSS3, Angular, Javascript</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="container-warped-staffing__item bkg-blue bkg-java">
              <div className="container-warped-staffing__item__text">
                <h3>Java</h3>
                <ul className="list-staffing-1">
                  <li className="bullet1">Especialistas en desarrollo de Back-end´s empresariales</li>
                  <li className="bullet1">Conocimientos de la plataforma Java</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container padding-0 padding-bottom-50">
      <div className="container-warped-staffing">
        <div className="row">
          <div className="col">
            <div className="container-warped-staffing__item large bkg-orange bkg-analista">
              <div className="container-warped-staffing__item__text">
                <h3>Analista/Tester</h3>
                <ul className="list-staffing-1">
                  <li className="bullet2">Especialistas en el levantamiento de requerimientos</li>
                  <li className="bullet2">Especialistas en la ejecución de pruebas de sistemas</li>
                  <li className="bullet2">Manejo de herramientas para pruebas automatizadas</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="container-warped-staffing__item large bkg-blue bkg-scrum">
              <div className="container-warped-staffing__item__text">
                <h3>Project Management</h3>
                <ul className="list-staffing-1">
                  <li className="bullet1">Especialistas en dirección de proyectos: Líder de Proyecto, Project Manager, Delivery Manager</li>
                  <li className="bullet1">Especialistas en metodologías de desarrollo ágil</li>
                  <li className="bullet1">Conocimientos de SCRUM y LEAN</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default QueHacemos