/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./globals/header"
import "../scss/layout.scss"

import FooterLogo from "../images/footer-logo.png"
import FooterPhone from "../images/footer-phone.png"
import FooterEmail from "../images/footer-email.png"

import Linkedin from "../images/linkedin.png"
import Instagram from "../images/instagram.png"
import Facebook from "../images/facebook.png"
import Twitter from "../images/twitter.png"

const Layout = ({ children, footer, menuactive }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} menuActive={menuactive} />
        <div className="layout-container">
          <main>{children}</main>

          <footer className={footer ? "footer-fixed" : "hidden"}>
            <div className="footer-logo">
              <img src={FooterLogo} alt="Empresa de desarrollo de software en la Ciudad de México"></img>
            </div>
            <div className="footer-contact">
              <div className="footer-contact__item">
                <img src={FooterPhone} alt="Phone"></img><br></br>
                <a href="tel:+0152525611733922">(52)56 1173 3922</a>
              </div>
              <div className="footer-contact__item">
                <img src={FooterEmail} alt="Email"></img><br></br>
                <a href="mailto:hola@high-flyers.mx">hola@high-flyers.mx</a>
              </div>
            </div>
            <div className="footer-social-contact">
            <a href="https://www.linkedin.com/company/highflyers-software" target="_blank" rel="noopener noreferrer">
              <img className="social-logo" src={Linkedin} alt="LinkedIn"></img>
            </a>
            <a href="https://www.instagram.com/highflyers.software/" target="_blank" rel="noopener noreferrer">
              <img className="social-logo" src={Instagram} alt="Instagram"></img>
            </a>
            <a href="https://www.facebook.com/highflyers.software" target="_blank" rel="noopener noreferrer">
              <img className="social-logo" src={Facebook} alt="Facebook"></img>
            </a>
            <a href="https://twitter.com/HighFlyers_mx" target="_blank" rel="noopener noreferrer">
              <img className="social-logo" src={Twitter} alt="Twitter"></img>
            </a>
            </div>
          </footer>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

if (typeof window !== 'undefined') {
  window.onscroll = function () {
    let height = document.documentElement.clientHeight;
    let currentScrollPos = window.pageYOffset;
    let header = document.getElementById("header-container");
    if(header) {
      if(currentScrollPos > (height - 87)) {
        header.classList.add("header--sticky");
      } else {
        header.classList.remove("header--sticky");
      }
    }
  }
}