import React from "react";
import Slider from "react-slick";

class SimpleSlider extends React.Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 6000,
      pauseOnHover: true,
      fade: true
    };
    return (
      <Slider {...settings}>
        <div>
          <p className="txt-description small">Nuestro trabajo consiste en reclutar, seleccionar y contratar a los mejores 
          profesionistas e integrarlos al equipo de trabajo de High-flyers. Nos encargamos 
          también de cubrir los gastos de nómina, prestaciones de ley, prestaciones superiores 
          a la ley y capacitación, así como de absorber los gastos que se derivan de la determinación 
          de los contratos laborales con nuestros colaboradores cuando así se requiera.</p>
        </div>
        <div>
          <p className="txt-description small">Nuestros clientes acuden a nosotros buscando especialistas en desarrollo de software para cubrir las demandas siempre exigentes de su organización y del mercado. Mediante un contrato de prestación de servicios profesionales, que cumple con las leyes y la normatividad mexicana, nosotros asignamos a una o varias personas de nuestro staff para integrarse a laborar con los equipos de trabajo de nuestros clientes, ya sea por un periodo corto o largo de tiempo;  en sus instalaciones o de forma remota; con tu equipo de cómputo o con el nuestro.</p>
        </div>
        <div>
          <p className="txt-description small">Cuando las personas/desarrolladores que asignamos a trabajar con nuestros clientes terminan las actividades que les fueron encomendadas, concluye el contrato de prestación de servicios y, los reintegramos al equipo de High-flyers con lo que están listos para emprender un nuevo reto profesional, ya sea para trabajar con otro de nuestros clientes o para participar en los proyectos internos de High-flyers.</p>
        </div>
        <div>
          <p className="txt-description small">Con el servicio de Staffing de High-flyers nuestros clientes transforman el proceso burocrático de contratación de personal, demandante en tiempo y esfuerzo, en un proceso ágil que les permite tener a los mejores profesionistas de forma prácticamente inmediata.</p>
        </div>
      </Slider>
    );
  }
}

export default SimpleSlider