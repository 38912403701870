import { Link } from "gatsby"
import React, { Component } from "react"

import { FaFacebookMessenger, FaWhatsapp } from "react-icons/fa"
import { MdMenu } from "react-icons/md"

class Header extends Component {
  constructor(props) {
    super(props);
    this.openMenu = this.openMenu.bind(this);
    this.changeState = this.changeState.bind(this);
    this.state = { active: false };
  }

  changeState(event) {
    this.setState({active: !this.state.active});
    document.removeEventListener("click", this.changeState, false);
  }

  openMenu() {
    this.setState({active: !this.state.active});
    if (!this.state.active) {
      document.addEventListener("click", this.changeState, false);
    } else {
      document.removeEventListener("click", this.changeState, false);
    }
  }

  render() {
    return (
  <header id={"header-container" + (this.props.menuActive === "noscroll" ? "_noscroll" : "")}>
    <div className="header-container">
      <h1>
        <Link to="/">
          <div className="header-container__logo"/>
        </Link>
      </h1>
      
      <div className={this.state.active ? 'header-container__menu--active' : "header-container__menu--inactive" }>
        <ul>
          <li>
            <Link className="agencia" to="/agencia-de-software" >
              Agencia de software 
            </Link>
          </li>
          <li>
            <Link className="staffing" to="/staffing-ti">
              Staffing TI
            </Link>
          </li>
          <li>
            <Link className="academia" to="/academia-de-software">
              Academia de software
            </Link>
          </li>
          <li>
            <Link className="contacto" to="/contacto">
              Contacto
            </Link>
          </li>
        </ul>
      </div>
      
      <div className="header-container__menu">
        <ul>
          <li className={this.props.menuActive === "agencia" ? "active" : ""}>
            <Link to="/agencia-de-software">
              Agencia de software
            </Link>
          </li>
          <li className={this.props.menuActive === "staffing" ? "active" : ""}>
            <Link to="/staffing-ti" onClick={this.changeMenuActive}>
              Staffing TI
            </Link>
          </li>
          <li className={this.props.menuActive === "academia" ? "active" : ""}>
            <Link to="/academia-de-software" onClick={this.changeMenuActive}>
              Academia de software
            </Link>
          </li>
          <li className={this.props.menuActive === "contacto" ? "active" : ""}>
            <Link to="/contacto" onClick={this.changeMenuActive}>
              Contacto
            </Link>
          </li>
        </ul>
      </div>

      <div className="header-social">
        <a href="http://m.me/highflyers.software" target="_blank" rel="noopener noreferrer">
          <FaFacebookMessenger
            color="white"
            size="2em"/>
        </a>
        <a href="https://wa.me/5215611733922" target="_blank" rel="noopener noreferrer">
          <FaWhatsapp
            color="white"
            size="2em"/>
        </a>
      </div>
      <div className="header-movil">
        <button type="button" onClick={this.openMenu}>
          <MdMenu
            color="white"
            size="3em"/>
        </button>
      </div>
    </div>
  </header>
  );
  }
}

export default Header